*,
::after,
::before {
    margin: 0%;
    padding: 0%;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    max-width: 100%;
}

.aboutus-hero-section {
    /* margin-top: 60px; */
    padding: 60px 0 199px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutus-heading h1 {
    width: 568px;
    font-size: 70px;
    font-weight: 600;
    line-height: 84.72px;
    letter-spacing: -0.03em;
    text-align: center;

    margin-bottom: 59px;
    /* border: 1px solid red; */

}

.aboutus-heading-para p {
    width: 517px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: -0.02em;
    text-align: center;
    color:
        rgba(128, 128, 128, 1);
    /* border: 1px solid red;    */

}

.careers-heading-para-text p {
    width: 264px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: -0.02em;
    text-align: center;
    /* border: 1px solid red; */
    color: rgba(128, 128, 128, 1);
    /* padding: 0px !important; */
}

.viewrolebtn {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0px 2px 1.6px rgba(0, 0, 0, 0.13);
    background: linear-gradient(rgba(85, 111, 249, 1), rgba(87, 112, 242, 1));
    border: none;
    border-radius: 4px;
    border-width: 0.70px;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 2px rgba(255, 255, 255, 0.35) inset;
    margin-top: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 130px;
}

.productcontent-aboutus-title {
    color: rgba(0, 0, 0, 1);
    /* margin: 5px 0; */
    font-size: 38px;
    font-weight: 600;
    line-height: 45.99px;
    letter-spacing: -0.03em;
    text-align: center;
    /* border: 1px solid red; */
    width: 310px;
}

.productcontent-para-content {
    width: 953px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 112px;
    /* border: 1px solid red; */

}

.productcontent-para-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(128, 128, 128, 1);
}





/* Roles section */

.roles-content-container {

    margin: 163px 0px 292px;
    /* border: 1px solid red; */
}


.roles-heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roles-heading-content h1 {

    width: 452px;
    font-size: 50px;
    font-weight: 600;
    line-height: 60.51px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(0, 0, 0, 1);

}



.roles-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 110px;

}

.roles-card-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.roles-card-content {
    /* width: 828px; */
    /* height: 110px; */
    border-radius: 12px;
    /* border: 1px solid rgba(204, 204, 204, 1); */
    box-shadow: 0px 4px 10.8px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 31px 35px 31px 25px;
    gap: 84px;
    /* border: 1px solid red;  */
}

.roles-card-content-box {
    display: flex;
    align-items: center;
    gap: 84px;
}

.roles-card-content-left {
    width: 153px;
    /* border: 1px solid red; */
}

.roles-card-content-left p {
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: rgba(0, 122, 255, 1);
    margin-bottom: 21px;
}

.roles-card-content-left h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    letter-spacing: -0.05em;
    text-align: left;

    color: rgba(0, 0, 0, 1);
}

.roles-card-content-center {
    display: flex;
    /* gap: 80px; */
    align-items: center;
    /* border: 1px solid red; */

}

.roles-card-content-center ul {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.roles-card-content-center ul :first-child {
    list-style: none;
    color:
        rgba(117, 117, 117, 1);
    margin-right: 84px;
    width: 78px !important;
}

.roles-card-content-center ul :nth-child(2) {
    color:
        rgba(117, 117, 117, 1);
    margin-right: 84px;

}


.roles-card-content-right {
    display: flex;
    gap: 80px;
    align-items: center;
    /* border: 1px solid red; */
}

.roles-card-content-right p {
    font-size: 12px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.04em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
}

.applybtn {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0px 2px 1.6px rgba(0, 0, 0, 0.13);
    background: linear-gradient(rgba(85, 111, 249, 1), rgba(87, 112, 242, 1));
    border: none;
    border-radius: 4px;
    border-width: 0.70px;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 2px rgba(255, 255, 255, 0.35) inset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 91px;
}



@media only screen and (max-width: 768px) {
    .aboutus-hero-section {
        /* margin-top: 60px; */
        padding: 60px 0 172px;
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .aboutus-heading h1 {
        width: 359px;
        font-size: 30px;
        font-weight: 700;
        line-height: 36.31px;
        letter-spacing: -0.03em;
        text-align: center;
        margin-bottom: 44px;
        /* border: 1px solid red; */

    }
    .aboutus-heading-para p {
        width: 275px;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.78px;
        letter-spacing: -0.02em;
        text-align: center;
        color:
            rgba(128, 128, 128, 1);
        /* border: 1px solid red; */
    
    }

    .productcontent-aboutus-title {
        color: rgba(0, 0, 0, 1);
        font-size: 30px;
        font-weight: 600;
        line-height: 36.31px;
        letter-spacing: -0.03em;
        text-align: center;
        width: 245px;
        /* border: 1px solid red; */
    }



    .productcontent-para-content {
        width: 953px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 16px;
        /* border: 1px solid rebeccapurple; */
        margin-top: 50px;
        padding: 0 50px;

    }


    .roles-content-container {

        margin: 184px 0px 176px;
        /* border: 1px solid red; */
    }

    .roles-heading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .roles-heading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .roles-heading-content h1 {

        width: 272px;
        font-size: 30px;
        font-weight: 600;
        line-height: 36.31px;
        letter-spacing: -0.03em;
        text-align: center;
        color: rgba(0, 0, 0, 1);

    }

    .roles-card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 81px;
        /* border: 1px solid red; */
    }

    .roles-card-content {
        /* width: 339px; */
        border-radius: 12px;
        border: 1px solid rgba(204, 204, 204, 1);
        box-shadow: 0px 4px 10.8px 0px rgba(0, 0, 0, 0.07);
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 24px 25px 20px 25px;
        gap: 39px;
        /* border: 1px solid red; */
    }

    .roles-card-content-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        /* border: 1px solid red; */
        /* width: 149px; */
    }

    .roles-card-content-left {
        width: 149px;
        /* border: 1px solid rgb(81, 0, 255); */

    }

    .roles-card-content-left p {
        font-size: 10px;
        font-weight: 600;
        line-height: 12.1px;
        text-align: left;
        color: rgba(0, 122, 255, 1);
        margin-bottom: 21px;
    }

    .roles-card-content-left h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 31.47px;
        letter-spacing: -0.05em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
    }


    .roles-card-content-center {
        display: flex;
        align-items: center;
        /* border: 1px solid red; */
    }

    .roles-card-content-center ul {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .roles-card-content-center ul :first-child {
        list-style: none;
        color:
            rgba(117, 117, 117, 1);
        margin-right: 0px;
        /* border: 1px solid red; */
        padding: 0px;
        margin-left: 0px;

    }

    .roles-card-content-center ul :nth-child(2) {
        color:
            rgba(117, 117, 117, 1);
        margin-right: 0px;

    }

    .roles-card-content-right {
        display: flex;
        gap: 38px;
        align-items: center;
        flex-direction: column;
        /* border: 1px solid red; */
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


*,
::after,
::before {
    margin: 0%;
    padding: 0%;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
    max-width: 100%;
}

.grow-business-first-section {
    /* border: 1px solid red; */
    padding: 0px 80px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.first-section-heading {
    font-size: 60px;
    font-weight: 700;
    width: 400px;
    line-height: 72px;
}

.first-section-heading-title-str {
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.first-section-heading-title-startup {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* border: 1px solid red; */
    gap: 15px;
}

.first-section-get-startup {
    position: relative;
    top: 20px;
}


.first-section-get-str {
    display: none;
}


.first-section-prices {
    font-size: 26px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    color: rgba(0, 122, 255, 1);
    background-color: rgba(231, 235, 255, 1);
    width: fit-content;
    border-radius: 4px 100px 100px 4px;
    padding: 14px 19px 14px 10px;
    margin-top: 42px;
}

.first-section-price del {
    font-weight: 300;
}

.current-price {
    font-size: 25.82px;
    font-weight: 600;
}




.left-section-para-grey {
    font-size: 18px;
    font-weight: 500;
    color: rgba(128, 128, 128, 1);
    margin-top: 22px;
}

.left-container-foot-section {
    /* border: 1px solid red; */
    margin-top: 160px;
}

.left-section-para-blue {
    display: flex;
    align-items: center;
    color: rgba(49, 46, 203, 1);
    font-size: 18px;
    font-weight: 500;
    /* border: 1px solid red; */


}

.left-section-para-blue img {
    margin-right: 7px;
}

.left-foot-card-section {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 462px;
    margin-top: 30px;
    gap: 33px;
}




.left-foot-card-section>div img {
    margin-right: 19px;
}

.left-foot-card-section-content {
    width: 227px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;

    /* border: 1px solid black; */

}

.left-section-para-blue-card-text {
    /* border: 1px solid red; */
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;

    width: 153px;
}

.left-foot-card-blue-section {
    /* border: 1px solid red;    */
    width: 378px;
    margin-top: 30px;
    padding: 20px;
    padding-bottom: 0px;
    background: rgba(231, 235, 255, 1);
    border-radius: 7px;
    border-width: 1px 6px 1px 1px;
    border-style: solid;
    border-color: rgba(152, 169, 254, 1);
}

.left-foot-card-blue-section p {
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26.63px;
    letter-spacing: -0.02em;
    text-align: left;
    color:
        rgba(49, 46, 203, 1);
}



.left-foot-section-para-grey {
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    color: rgba(119, 119, 119, 1);
    margin-top: 31px;
}

.left-container-foot-section-mobile {
    display: none;
}

.right-section-blue-para {
    font-size: 18px;
    font-weight: 500;
    color: rgba(49, 46, 203, 1);
    display: flex;
    align-items: center;
}

.contact-form {
    width: 459px;
    border-radius: 12px;
    border: 1px solid rgba(204, 204, 204, 1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    padding: 20px;
    margin-top: 28px;
}

.contact-form-heading {
    font-size: 26px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.contact-form-para-grey {
    font-size: 14px;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    margin-top: 22px;
}

#txtName {
    /* margin-top: 37px; */
}

#txtName,
#txtEmail,
#txtMobile {
    margin-bottom: 13px;
}

#txtName,
#txtEmail,
#txtMobile,
#lstCity {
    font-size: 16px;
    font-weight: 400;
    color: rgba(17, 17, 19, 0.6);
    padding: 13px 17px;
    border: 1px solid rgba(17, 17, 19, 0.2);
    width: 100%;
}

#lstCity {
    margin: 0%;
}

.contact-form-whatsapp-link {
    display: flex;
    align-items: center;
    margin-top: 80px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
}

.check-logo {
    margin-right: 10px;
}

.btn-whatsapp {
    border: none;
    margin-left: 8px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: rgba(51, 51, 51, 1);
}

.btn-getStarted {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0px 2px 1.6px rgba(0, 0, 0, 0.13);
    background: linear-gradient(rgba(85, 111, 249, 1), rgba(87, 112, 242, 1));
    border: none;
    border-radius: 4px;
    border-width: 0.70px;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 2px rgba(255, 255, 255, 0.35) inset;
    margin-top: 28px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 175px;
}

.outer-shaddow {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: 0px 3px 1px rgba(153, 153, 153, 0.25);
    border: none;
}

.grow-business-first-section-end-mobile {
    display: none;
}

@media screen and (max-width: 768px) {

    .grow-business-first-section {
        /* border: 1px solid red; */
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 25px;

    }

    .first-section-left-container {}

    .left-container-head-section {
        /* border: 1px solid black; */
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .first-section-heading-title-startup {
        display: flex;
        flex-direction: column;

        gap: 15px;
    }

    .first-section-get-startup {
        position: relative;
        top: 0px;
    }


    .first-section-heading {
        font-size: 30px;
        font-weight: 700;
        width: 200px;
        line-height: 36.31px;
        text-align: center;
    }

    .first-section-get-str {
        display: block;
    }

    .first-section-prices {

        font-family: Inter;
        font-size: 25.82px;
        font-weight: 400;
        line-height: 31.25px;
        letter-spacing: -0.02em;
        text-align: center;
        color: rgba(0, 122, 255, 1);
        background-color: rgba(231, 235, 255, 1);
        width: fit-content !important;
        border-radius: 100px;
        padding: 14px 21px !important;
        margin-top: 42px;
        /* border: 1px solid; */

    }

    .first-section-price del {
        font-weight: 300;

    }

    .current-price {
        font-size: 25.82px;
        font-weight: 600;
    }

    .left-section-para-grey {
        font-size: 14px;
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
        margin-top: 22px;
    }



    .left-container-foot-section {
        display: none;
    }

    .right-section-blue-para {
        display: none;

    }

    .left-container-foot-section-mobile {
        display: block;
        margin-top: 80px;
    }


    .left-foot-card-blue-section {
        /* border: 1px solid red;    */
        width: 338px;
        margin-top: 19px;
        padding: 20px;
        padding-bottom: 0px;
        background: rgba(231, 235, 255, 1);
        border-radius: 7px;
        border-width: 1px 6px 1px 1px;
        border-style: solid;
        border-color: rgba(152, 169, 254, 1);
    }

    .left-foot-card-blue-section p {
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 26.63px;
        /* letter-spacing: -0.02em; */
        text-align: left;
        color: rgba(49, 46, 203, 1);
        /* border: 1px solid red; */
    }


    .contact-form-heading {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
    }

    .contact-form-para-grey {
        font-size: 14px;
        font-weight: 400;
        color: rgba(128, 128, 128, 1);
        margin-top: 22px;
    }

    .contact-form-whatsapp-link {
        display: flex;
        align-items: center;
        margin-top: 80px;
        font-size: 13px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
    }

    .grow-business-first-section-end-mobile {
        display: block;
        /* border: 1px solid red; */
        margin-top: 61px;
    }

    .left-foot-card-section {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* border: 1px solid red; */
        width: 462px;
        margin-top: 30px;
        gap: 25px;
    }

    .left-foot-card-section>div {
        display: flex;
        /* justify-content: star; */
        align-items: center;
    }

    .left-foot-card-section>div>div {
        width: 145px;
        /* border: 1px solid red; */
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        text-align: left;
        color: rgba(0, 0, 0, 1);
    }


}

@media screen and (min-width:1279px) and (max-width:1450px) {

    .first-section-heading {
        font-size: 43px;
        font-weight: 750;
        width: 300px;
        line-height: 50px;
        /* border: 1px solid red; */
    }

}

/* for extra-small desktop  */
@media screen and (min-width:993px) and (max-width:1278px) {
    .left-section-para-grey {
        font-size: 18px;
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
        margin-top: 22px;
        /* border: 1px solid red; */
        width: 520px;
    }

}


/* guid section started ---- */
.guid-card-section {
    /* border: 1px solid red; */
    padding: 0px 80px;
    margin-top: 192px;
    margin-bottom: 144px;

}

.guid-head-section {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.guid-section-blue-para {
    font-size: 16px;
    font-weight: 600;
    color: rgba(85, 111, 249, 1);
    text-align: center;
}

.guid-section-heading {
    font-size: 38px;
    font-weight: 600;
    line-height: 45.99px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    width: 204px;
    /* letter-spacing: -1px; */
    margin-top: 30px;
}

.guid-cards {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 52px;
    margin-top: 104px;
}

.guid-card {
    width: 309px;
    height: 346px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 10pxs;
    /* padding: 20px 22px; */
    display: flex;
    flex-direction: column;
    justify-content: end;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    /* border: 1px solid green; */
}

.guid-card-container_box {
    /* border: 1px solid green; */
    width: 309px;
    height: 162px;
    padding: 20px 22px;
   
}

.guid-card-title {
    font-size: 30px;
    font-weight: 500;
    color: rgba(11, 11, 11, 1);
    /* border: 1px solid red; */
}

.guid-card-para {
    font-size: 18px;
    font-weight: 500;
    line-height: 19.78px;
    letter-spacing: -0.02em;
    text-align: left;
    /* border: 1px solid black; */
    color: rgba(128, 128, 128, 1);
    margin-top: 20px;
    width: 232px;
}

@media screen and (max-width: 768px) {
    .guid-card-section {
        /* border: 1px solid red; */
        padding: 0px 40px;
        margin-top: 192px;
    }

    .guid-head-section {
        display: flex;
        align-items: center;
        flex-direction: column;
        /* border: 1px solid red; */

    }


    .guid-section-heading {
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        width: 161px;
        text-align: center;
        letter-spacing: -1px;
        margin-top: 30px;
    }

    .guid-cards {
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 60px;
    }

    .guid-card {
        width: 309px;
        height: 346px;
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 10pxs;
        /* padding: 20px 22px; */
        display: flex;
        flex-direction: column;
        justify-content: end;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        /* border: 1px solid green; */
    }

}







/* guide card section ended ------- */

/* eligibility section started ---- */
.eligibility-section {
    /* border: 1px solid red; */
    margin-top: 144px;
    padding: 63px 80px 63px 80px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eligibility-head-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eligibility-section-para-blue {
    font-size: 16px;
    font-weight: 500;
    color: rgba(85, 111, 249, 1);

}

.eligibility-section-heading {
    font-size: 38px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    /* border: 1px solid red; */
    width: 304px;
    text-align: center;
    margin-top: 30px;
}

.eligibility-card-container1,
.eligibility-card-container2 {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.eligibility-card-container1 {
    margin-top: 182px;
}

.eligibility-card-container2 {
    margin-top: 147px;
    padding-top: 86px;
    border-top: 1px dashed rgba(119, 119, 119, 1);
}

.eligibilty-card {
    width: 354px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* border: 1px solid red; */
}

.eligibility-card-title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    line-height: 21px;
    width: 232px;
    margin-top: 31px;
}

.eligibility-card-para {
    font-size: 18px;
    font-weight: 400;
    color: rgba(163, 163, 163, 1);
    line-height: 21px;
    letter-spacing: -0.5px;
    margin-top: 31px;
}

@media screen and (max-width: 768px) {

    .eligibility-section {
        margin-top: 144px;
        padding: 0px 40px;
        background-color: rgba(248, 248, 248, 1);
        padding-top: 50px;

    }

    .eligibility-head-section {
        display: flex;
        flex-direction: column;
        align-items: start;
        /* border: 1px solid red; */
        /* margin-top: 50px; */

    }

    .eligibility-section-para-blue {
        font-size: 16px;
        font-weight: 500;
        color: rgba(85, 111, 249, 1);
    }

    .eligibility-section-heading {
        font-size: 30px;
        font-weight: 600;
        line-height: 36.31px;
        letter-spacing: -0.03em;
        text-align: left;

        color: rgba(0, 0, 0, 1);
        /* border: 1px solid red; */
        width: 304px;
        text-align: start;
        margin-top: 24px;
    }

    .eligibility-card-container1,
    .eligibility-card-container2 {
        display: flex;
        justify-content: start;
        gap: 50px;
        flex-wrap: wrap;
        /* border: 1px solid red; */
    }

    .eligibility-card-container1 {
        margin-top: 104px;
    }

    .eligibility-card-container2 {
        margin-top: 50px;
        padding-top: 0px;
        border-top: none;
    }


    .eligibilty-card {
        width: 354px;
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: start;
        /* border: 1px solid red; */
    }

    .eligibility-card-title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        line-height: 21px;
        width: 232px;
        margin-top: 31px;
    }

    .eligibility-card-para {
        font-size: 18px;
        font-weight: 400;
        color: rgba(163, 163, 163, 1);
        line-height: 21px;
        letter-spacing: -0.5px;
        margin-top: 31px;
    }
}

@media screen and (min-width:1279px) and (max-width:1450px) {
    .eligibility-section {
        /* border: 1px solid red; */
        margin-top: 144px;
        padding: 63px 140px 63px 140px;
        background-color: rgba(248, 248, 248, 1);
    }

    .eligibility-head-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* border: 1px solid red; */
        /* margin-top: 50px; */

    }

    .eligibility-section-heading {
        font-size: 30px;
        font-weight: 600;
        line-height: 36.31px;
        letter-spacing: -0.03em;

        color: rgba(0, 0, 0, 1);
        /* border: 1px solid red; */
        width: 304px;
        text-align: center;
        margin-top: 24px;
    }


    .eligibility-card-container1,
    .eligibility-card-container2 {
        display: flex;
        justify-content: start;
        gap: 30px;
        flex-wrap: wrap;
        /* border: 1px solid red; */
    }

    .eligibilty-card {
        width: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* border: 1px solid red; */
    }

}


/* Eligibility section Ended------- */



/* .differenciate-section started - */
.differenciate-section {
    /* border: 1px solid red; */
    margin-top: 139px;
    padding: 0px 167px;
}

.differenciate-para-blue {
    font-size: 16px;
    font-weight: 500;
    color: rgba(85, 111, 249, 1);
}

.differenciate-heading {
    font-size: 38px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    width: 410px;
    line-height: 45px;
    letter-spacing: -1px;
    margin-top: 30px;
}

.difference-column-container {
    margin-top: 178px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 49px;
}

.differenciate-column {
    /* border: 1px solid red; */
    width: 354px;
}

.column-title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.column-para {
    font-size: 18px;
    font-weight: 400;
    color: rgba(163, 163, 163, 1);
    line-height: 21px;
    letter-spacing: -0.5px;
    margin-top: 53px;
}

@media screen and (max-width: 768px) {
    .differenciate-section {
        /* border: 1px solid red; */
        margin-top: 126px;
        padding: 0px 40px;
    }

    .differenciate-heading {
        font-family: Inter;
        font-size: 29px;
        font-weight: 600;
        line-height: 36.31px;
        letter-spacing: -0.03em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        width: 334px;
        /* border: 1px solid red; */
        margin-top: 30px;
    }

    .difference-column-container {
        margin-top: 178px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 49px;
        /* border: 1px solid red; */
    }

    .differenciate-column {
        /* border: 1px solid red; */
        width: 354px;
    }

    .column-title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
    }
}

@media screen and (min-width:1279px) and (max-width:1450px) {
    .differenciate-section {
        /* border: 1px solid red; */
        margin-top: 139px;
        /* padding: 0px 40px; */
        padding: 63px 140px 63px 140px;

    }

    .difference-column-container {
        margin-top: 178px;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 49px;
        /* justify-content: space-between; */
        /* row-gap: 10px; */
        /* border: 1px solid red; */
    }

    .differenciate-column {
        /* border: 1px solid red; */
        width: 300px;
    }
}



/* registration section started ----------- */

.registration-document-section {
    padding: 139px 167px;
    /* border: 1px solid red; */
    background-color: rgba(248, 248, 248, 1);
}

.registration-document-blue-para {
    /* padding-top: 130px; */
    font-size: 16px;
    font-weight: 500;
    color: rgba(85, 111, 249, 1);
}

.registration-document-title {
    font-size: 38px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    margin-top: 30px;
    width: 294px;
    line-height: 45px;
    letter-spacing: -2px;
}

.document-column-container {
    margin-top: 89px;
    display: flex;
    justify-content: space-between;
    gap: 49px;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}


.document-column {
    width: fit-content;
    margin: 0%;
    /* border: 1px solid red; */

}

.document-column ul {
    padding: 0px;
    margin: 0px;

}


.document-column-title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    list-style: none;
    margin-bottom: 37px;
}

.document-column li:not(:nth-child(1)) {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 27px;
    border: 0.9px solid rgba(226, 226, 226, 1);
    border-radius: 106px;
    padding: 10px 12px 10px 6px;
    width: fit-content;
    display: flex;
    align-items: center;
}

.document-column li:not(:nth-child(1)) img {
    margin-right: 10.62px;
}

@media screen and (max-width: 768px) {

    .registration-document-section {
        margin-top: 126px;
        /* border: 1px solid red; */
        padding: 0px 40px;
        padding-top: 50px;

        background-color: rgba(248, 248, 248, 1);
    }


    .registration-document-blue-para {
        font-size: 16px;
        font-weight: 500;
        color: rgba(85, 111, 249, 1);
    }

    .registration-document-title {
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        margin-top: 24px;
        width: 294px;
        line-height: 45px;
        letter-spacing: -2px;
        /* border: 1px solid red; */
    }

    .document-column-container ul {
        padding: 0%;
        margin: 0%;
    }

    .document-column-container {
        margin-top: 53px;
        display: flex;
        justify-content: start;
        align-items: start;
        gap: 49px;
        flex-wrap: wrap;
        /* border: 1px solid red; */
        padding-left: 0px;

    }

    .document-column {
        width: fit-content;
        margin: 0%;
    }


    .document-column-title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        list-style: none;
        margin-bottom: 37px;
        margin-left: 0px;
    }


    .document-column li:not(:nth-child(1)) {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        letter-spacing: -0.04em;
        text-align: left;
        margin-bottom: 21px;
        border: 0.9px solid rgba(226, 226, 226, 1);
        border-radius: 106px;
        /* padding: 10px 12px 10px 6px; */
        width: fit-content;
        display: flex;
        align-items: center;
        /* border: 1px solid red; */
    }

}

@media screen and (min-width:1279px) and (max-width:1450px) {


    .registration-document-section {
        margin-top: 139px;
        /* border: 1px solid red; */
        padding: 63px 140px 63px 140px;
        background-color: rgba(248, 248, 248, 1);
    }



    .document-column-container {
        margin-top: 89px;
        display: flex;
        gap: 13px;
        flex-wrap: wrap;
        /* border: 1px solid green; */

    }

    .document-column {
        width: fit-content;
        margin: 0%;
        /* border: 1px solid red; */

    }

    .document-column-title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        list-style: none;
        margin-bottom: 37px;
    }

    .document-column li:not(:nth-child(1)) {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 27px;
        border: 0.9px solid rgba(226, 226, 226, 1);
        border-radius: 106px;
        padding: 10px 12px 10px 6px;
        width: fit-content;
        display: flex;
        align-items: center;
        /* border: 1px solid red; */
    }
}